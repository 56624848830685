// Overzicht van de reservering met de mogelijkheid om de datum aan te passen en een notitie toe te voegen
import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Button, Box, TextField} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/datepicker-custom.css';
import ReserveringPopup from './ReserveringPopup';


const ReserveringsData = ({   
    handleMaterialClickForOverlap,
    overlappingReservations,
    openReservationDialog,
    setOpenReservationDialog,
    notitie, 
    selectedMaterials,
    setSelectedMaterials,
    setNotitie, reservedMaterials, geselecteerdeDraaidag, currentReservation, updateReservering, getDatesInRange, reservations,postOrUpdateReservering }) => {
    console.log("resevaton in functie", currentReservation    );

    // Stel veilige standaardwaarden in als reservation.fields.Start of reservation.fields.Eind null of undefined zijn

    const initialStartDate = currentReservation && currentReservation.fields && currentReservation.fields.Start
        ? new Date(currentReservation.fields.Start)
        : new Date(geselecteerdeDraaidag.fields.StartDatum);

    const initialEndDate = currentReservation && currentReservation.fields && currentReservation.fields.Eind
        ? new Date(currentReservation.fields.Eind)
        : new Date(geselecteerdeDraaidag.fields.EindDatum);

    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const [popupOpen, setPopupOpen] = useState(false);  // Added this line for popup state

    // Functies om de datum aan te passen (vervang dit door jouw logica)
    const handleStartDateChange = (newStartDate) => {
        setStartDate(newStartDate);
        updateReservering(currentReservation.id, newStartDate, endDate);

        // Voeg hier eventueel jouw logica toe voor het bijwerken van de reservering
    };

    const handleEndDateChange = (newEndDate) => {
        setEndDate(newEndDate);
        updateReservering(currentReservation.id, startDate, newEndDate);
        // Voeg hier eventueel jouw logica toe voor het bijwerken van de reservering
    };


    // Functie om de dialoogbox te openen
    const openPopup = () => {
        setPopupOpen(true);
    };

    // Formatteer de datums voor weergave
    const formatDate = (datumString) => {
        const datum = new Date(datumString);
        return datum.toLocaleDateString('nl-NL', {
            day: '2-digit', month: '2-digit', year: 'numeric'
        });
    };

    const handleFieldBlur = async () => {
        // Update database
        postOrUpdateReservering(startDate, endDate, selectedMaterials);
    };

    useEffect(() => {
        console.log('currentReservation in ChildComponent:', currentReservation);
    }, [currentReservation]);

    useEffect(() => {
        if (currentReservation && currentReservation.fields) {
            // Stel veilige standaardwaarden in als reservation.fields.Start of reservation.fields.Eind null of undefined zijn
            const safeStart = currentReservation.fields.Start ? new Date(currentReservation.fields.Start) : new Date();
            const safeEnd = currentReservation.fields.Eind ? new Date(currentReservation.fields.Eind) : new Date();
    
            setStartDate(safeStart);
            setEndDate(safeEnd);
        }
    }, [ currentReservation]); // Voer uit wanneer open of currentReservation verandert
        
    const handleNotitieChange = (event) => {
        setNotitie(event.target.value);
    };

    if (!currentReservation || !currentReservation.fields) {
        // Return een foutmelding of alternatieve UI als 'reservation' niet is geladen
        return (
            <Box sx={{ minHeight: '1vh', padding: 1 }}>
                <Paper elevation={1} sx={{ padding: 2, margin: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="body1" component="div">
                        Nog geen reservering aangemaakt......
                    </Typography>
                </Paper>
            </Box>
        );
    }

    return (

        <div>
        <Box sx={{ minHeight: '1vh', padding: 0 }}>
            <Paper elevation={1 } sx={{ padding: 2, margin: 0 }}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Typography variant="body1" component="h2" sx={{ fontWeight: 'bold', marginBottom: 0 }}>
                            {`Reservering: ${currentReservation.fields.reserveringNummer}`}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={true}>
                        <Typography variant="body1">
                            {`van ${formatDate(currentReservation.fields.Start)} tot ${formatDate(currentReservation.fields.Eind)}`}
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={openPopup}>
                                Wijzig datum
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField 
                            fullWidth 
                            multiline 
                            onBlur={handleFieldBlur} 
                            label="Voeg notitie toe aan reservering" 
                            value={notitie} 
                            onChange={handleNotitieChange}
                            variant="outlined"
                            sx={{ marginTop: 1 }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>

        <ReserveringPopup
            open={popupOpen}
            onClose={() => setPopupOpen(false)}
            currentReservation={currentReservation}
            updateReservation={updateReservering}
            draaidag={geselecteerdeDraaidag}
            getDatesInRange={getDatesInRange}
            reservations={reservations}
            reservedMaterials={reservedMaterials}
            handleMaterialClickForOverlap={handleMaterialClickForOverlap}
            overlappingReservations={overlappingReservations}
            openReservationDialog={openReservationDialog}
            setOpenReservationDialog={setOpenReservationDialog}
            selectedMaterials={selectedMaterials}
            setSelectedMaterials={setSelectedMaterials}
            postOrUpdateReservering={postOrUpdateReservering}
        />
        
        </div>
    );
};

export default ReserveringsData;
