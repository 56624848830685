    // MaterialReservationApp.js
    import React, { useState } from "react";
    import axios from 'axios';
    import { Container, Typography, Box } from "@mui/material";
    import {DraaidagenOverzicht} from "./DraaidagenOverzicht";
    import {MaterialenOverzicht} from "./MaterialenOverzicht";

    export function AppContent() {
        const [geselecteerdeDraaidag, setGeselecteerdeDraaidag] = useState(null);
        const [currentReservation, setCurrentReservation] = useState(null); // object of resrvation of draaidag (1)
    
        const [reservations, setReservations] = useState({}); 
        const [reservationsData, setReservationsData] = useState({}); 
        const [currentView, setCurrentView] = useState('month'); // Default view
        // check dubbele reserveringen voor draaidag
        const [dubbeleReserveringen, setDubbeleReserveringen] = useState([]);

        const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
        const AIRTABLE_RESERVATION_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tbl1aplX8tnwYIb4M'
        const AIRTABLE_JUNCTION_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblWxxnUVWgZsACXa'

        const HEADERS = {
            Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
        };


        const initReservations = async () => {
            const reservationsData = await fetchAndProcessReservations();
            setReservations(reservationsData);

            console.log("reservationsData", reservationsData);
            console.log("reservations", reservations);
        };

        async function getAllReservations(endpoint, headers) {
            let reservations = [];
            let offset = null;
        
            do {
                const response = await axios.get(endpoint, {
                    headers: headers,
                    params: offset ? { offset: offset } : {}
                });
                console.log("responseXXX", response);

                reservations = reservations.concat(response.data.records);
                console.log("reservations", reservations);
                offset = response.data.offset;
                console.log("offset", offset);
            } while (offset);
            console.log("offset2", offset);

            return reservations;
        }

        // haal alle reserveringen op met data erin en stop de data in een array met materiaal ID als key
        const fetchAndProcessReservations = async () => {
            try {
                //const reservationsResponse = await axios.get(AIRTABLE_RESERVATION_ENDPOINT, { headers: HEADERS });
                console.log("allReservations");

                const allReservations  = await getAllReservations(AIRTABLE_RESERVATION_ENDPOINT, HEADERS);

                
                console.log("allReservations", allReservations);
                const reservationsData = {};

                setReservationsData(allReservations);
                
                allReservations.forEach(record => {
                    if (record.fields && record.fields.Materiaal && Array.isArray(record.fields.Materiaal)) {
                        record.fields.Materiaal.forEach(materialId => {
                            if (!reservationsData[materialId]) {
                                reservationsData[materialId] = [];
                            }
                            if (record.fields.tussenData) {
                                reservationsData[materialId].push(...record.fields.tussenData.split(', '));
                            }
                        });
                    }
                });
                
                return reservationsData;
            } catch (error) {
                console.error("Er ging iets mis met het ophalen van de reserveringen:", error);
                return {}; // Terugkeer van een leeg object bij een fout
            }
        };


        // controleer of er dubbele reserveringen bestaan voor een draaidag
        const fetchReserveringen = async (draaidagId) => {
            try {
                const encodedFormula = encodeURIComponent(`IDdraaidagOutlook='${draaidagId}'`);            
                const response = await axios.get(
                    `${AIRTABLE_RESERVATION_ENDPOINT}?filterByFormula=${encodedFormula}`,
                    { headers: HEADERS }
                );
                console.log("responseKK", response.data.records);    
                // Als er records zijn gevonden, return true (indicatie van een bestaande reservering)
                return response;
            } catch (error) {
                console.error("Fout bij het controleren van bestaande reserveringen:", error);
                throw error; // Gooi de fout zodat de aanroepende functie deze kan afhandelen
            }
        };
    
        const fetchAlleReserveringen = async () => {
            let alleReserveringen = [];
            let offset; // Airtable gebruikt offset om de volgende pagina van records aan te duiden
            try {
                do {
                    let url = AIRTABLE_RESERVATION_ENDPOINT;
                    if (offset) {
                        url += `?offset=${offset}`;
                    }
        
                    const response = await axios.get(url, { headers: HEADERS });
        
                    alleReserveringen = alleReserveringen.concat(response.data.records);
                    offset = response.data.offset; // Update de offset met de waarde van de response, indien aanwezig
                } while (offset); // Blijf dit doen totdat er geen offset meer is, wat betekent dat je alle records hebt
        
                console.log("Alle reserveringen opgehaald:", alleReserveringen);
                return alleReserveringen;
            } catch (error) {
                console.error("Error fetching reservations:", error);
                return []; // Terugkeer van een lege lijst in geval van een fout
            }
        };
        
        
        // Delete a reservation and all related records from Airtable
        const handleDeleteReservation = async (reservationId) => {
            try {
                // 1. Haal eerst alle gerelateerde records op uit de junction table
    
                const encodedFormula = encodeURIComponent(`{ReserveringID}='${reservationId}'`);
                const relatedRecordsResponse = await axios.get(
                    `${AIRTABLE_JUNCTION_ENDPOINT}?filterByFormula=${encodedFormula}`,
                    { headers: HEADERS }
                );
        
                if (relatedRecordsResponse.status === 200 && relatedRecordsResponse.data.records) {
                    // 2. Verwijder alle gerelateerde records
                    for (const record of relatedRecordsResponse.data.records) {
                        await axios.delete(`${AIRTABLE_JUNCTION_ENDPOINT}/${record.id}`, {
                            headers: HEADERS
                        });
                    }
        
                    // 3. Verwijder de hoofdreservering nadat alle gerelateerde records zijn verwijderd
                    const response = await axios.delete(`${AIRTABLE_RESERVATION_ENDPOINT}/${reservationId}`, {
                        headers: HEADERS
                    });
        
                    if (response.status === 200) {
                        // Update de lokale state om de verwijderde reservering te verwijderen
                        initReservations();
    
                    } else {
                        console.error('Failed to delete the reservation:', response.status, response.statusText);
                        // Toon een foutmelding aan de gebruiker
                    }
                } else {
                    console.error('Failed to fetch related records:', relatedRecordsResponse.status, relatedRecordsResponse.statusText);
                    // Toon een foutmelding aan de gebruiker
                }
            } catch (error) {
                console.error("Error during the delete operation: ", error);
                // Toon een foutmelding aan de gebruiker
            }
        };
  

        return (

            <Container maxWidth="lg" style={{ marginTop: '0px' }}>
                
                    {
                        geselecteerdeDraaidag ? 
                            <MaterialenOverzicht 
                                geselecteerdeDraaidag={geselecteerdeDraaidag} 
                                setGeselecteerdeDraaidag={setGeselecteerdeDraaidag}
                                reservations={reservations}
                                setReservations={setReservations} 
                                reservationsData={reservationsData}
                                setReservationsData={setReservationsData}
                                fetchReserveringen={fetchReserveringen}
                                setDubbeleReserveringen={setDubbeleReserveringen}
                                dubbeleReserveringen={dubbeleReserveringen}
                                handleDeleteReservation={handleDeleteReservation}
                                fetchAndProcessReservations={fetchAndProcessReservations}
                                initReservations={initReservations}
                                currentReservation={currentReservation}
                                setCurrentReservation={setCurrentReservation}

                            /> 
                        : 
                            <DraaidagenOverzicht 
                                setGeselecteerdeDraaidag={setGeselecteerdeDraaidag} 
                                reservations={reservations}
                                setReservations={setReservations}
                                setReservationsData={setReservationsData}
                                reservationsData={reservationsData}
                                currentView={currentView}
                                setCurrentView={setCurrentView}
                                fetchReserveringen={fetchReserveringen}
                                fetchAlleReserveringen={fetchAlleReserveringen}
                                setDubbeleReserveringen={setDubbeleReserveringen}
                                dubbeleReserveringen={dubbeleReserveringen}
                                handleDeleteReservation={handleDeleteReservation}
                                initReservations={initReservations}
                                fetchAndProcessReservations={fetchAndProcessReservations}
                                currentReservation={currentReservation}
                                setCurrentReservation={setCurrentReservation}
                            />
                    }
                
            </Container>

        );
    }
    