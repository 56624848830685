// DraaidagenOverzicht.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, List, ListItem, ListItemText, CircularProgress, Grid } from '@mui/material';
//import Chip from '@material-ui/core/Chip';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useMsal } from "@azure/msal-react";
import { Fab, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DubbeleReserveringenDialog from './DubbeleReserveringenDialog';

import MismatchedReservations from './MismatchedReservations';
import { set } from 'lodash';


moment.locale('nl-NL',{
	week: {
		dow: 1 //Monday is the first day of the week.
	}
}); // or 'nl' for Dutch
const localizer = momentLocalizer(moment);
//moment.tz.setDefault('UTC')
localizer.segmentOffset = 0


const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;

// get draaidagen
const AIRTABLE_DRAAIDAG_ENDPOINT = `https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblG99gyQkiLg2wM1?sort[0][field]=StartDatum&sort[0][direction]=asc`;
const AIRTABLE_RESERVATION_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tbl1aplX8tnwYIb4M'
const AIRTABLE_JUNCTION_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblWxxnUVWgZsACXa'


const HEADERS = {
  Authorization: `Bearer ${API_KEY}`,
  'Content-Type': 'application/json'
};


const projectTypeColors = {
  "Video": "#FFD1DC",    // Pastel Pink
  "Testimonial": "#FFE4D4", // Pastel Peach
  "Interview": "#FFF4CC",  // Pastel Yellow
  "Registratie": "#DCFFD1",  // Pastel Green
  "Registratie met interviewer": "#D1FFEB",  // Pastel Turquoise
  "Aftermovie": "#D1F2FF",  // Pastel Blue
  "TV Commercial": "#DAD1FF",  // Pastel Lavender
  "Online Commercial": "#FFD1F4",  // Pastel Magenta
  "Animatiefilm": "#FFCCF2",  // Pastel Rose
  "Infographic": "#F2D1FF",  // Pastel Purple
  "Webinar": "#FFEBD1",  // Pastel Apricot
  "Explainer": "#F9D1FF",  // Pastel Mauve
  "Campagne": "#E8D1FF",  // Pastel Lilac
  "Podcast": "#FFD9D6",  // Pastel Coral
  "Vlog": "#FFE0D1",  // Pastel Tangerine
  "Fotografie": "#D9E9FF",  // Pastel Periwinkle
  // ... you can continue adding more mappings
};

// Definieer een array van kleurtoewijzingen
const eventColorExplanation = [
  { label: "Jouw draaidag, materiaal gereserveerd", color: "#8cbd4c" },
  { label: "Jouw draaidag, geen materiaal gereserveerd", color: "#de6a87" },
  { label: "Andere draaidag, materiaal gereserveerd", color: "#cbe7df" },
  { label: "Andere draaidag, geen materiaal gereserveerd", color: "#ead1c0" },
  { label: "Draaidag met dubbele reservering", color: "#FF0000" },
  
  // Voeg hier eventuele andere toewijzingen toe
];



export function DraaidagenOverzicht({initReservations, fetchAlleReserveringen, fetchReserveringen, handleDeleteReservation, setDubbeleReserveringen, dubbeleReserveringen, setGeselecteerdeDraaidag, setCurrentReservation, currentReservation, reservations, setReservations, setReservationsData, reservationsData, currentView, setCurrentView  }) {
    const [draaidagen, setDraaidagen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedDateEvents, setSelectedDateEvents] = useState([]);
    const [email, setEmail] = useState(null);
    const [legendaOpen, setLegendaOpen] = useState(false); // legenda open/dicht
    // remember the view 

    const [dubbeleReserveringenDialogOpen, setDubbeleReserveringenDialogOpen] = useState(false);

    const [draaidagenMetDubbeleReserveringen, setDraaidagenMetDubbeleReserveringen] = useState([]);
    const [toonAlertDubbeleReserveringen, setToonAlertDubbeleReserveringen] = useState(false);


    const { instance } = useMsal();
    
    const activeAccount = instance.getActiveAccount();

    // Fetch draaidagen from backend
    async function fetchDraaidagenFromBackend() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CURRENT_URL}/Api/draaidagen`); 
        setDraaidagen(response.data);
        console.log("Draaidagen fetched from backend:", response.data);
      } catch (error) {
        console.error("Something went wrong while fetching draaidagen from backend:", error);
      } finally {
        setLoading(false);
      }
    }

  

    // haal alle reserveringen op met data erin en stop de data in een array met materiaal ID als key
    useEffect(() => {
      initReservations();
    }, []); // Lege dependency array zorgt ervoor dat dit alleen bij initialisatie uitgevoerd wordt
    

    useEffect(() => {
        if (activeAccount) {
          // strip '-' from email adress, because in airtable all users are registerd without '-' after the @
          setEmail(activeAccount.username.replace(/-/g, ""));
          //setEmail("ton@fullframe.nl");
        } else {
            setEmail(null);
        }
    }, [activeAccount]);

    useEffect(() => {
      const identificeerDubbeleReserveringen = (reserveringen) => {
        const draaidagCounts = reserveringen.reduce((acc, reservering) => {
          // Veronderstelling: 'Draaidag' veld bevat een array van draaidag ID's (ook als er maar één ID is)
          const draaidagIds = reservering.fields.DraaidagOutlook || []; // fallback naar lege array als 'Draaidag' undefined is
          draaidagIds.forEach(draaidagId => {
            acc[draaidagId] = (acc[draaidagId] || 0) + 1;
          });
          return acc;
        }, {});

        const dubbeleDraaidagen = Object.keys(draaidagCounts).filter(draaidagId => draaidagCounts[draaidagId] > 1);
        return dubbeleDraaidagen; // Geeft een array van draaidag ID's die meer dan één keer voorkomen
      };

      
      
      const haalEnVerwerkReserveringen = async () => {
        const reserveringen = await fetchAlleReserveringen();
        const dubbeleDraaidagenIds = identificeerDubbeleReserveringen(reserveringen);
        setDraaidagenMetDubbeleReserveringen(dubbeleDraaidagenIds); // Update de staat met de IDs van draaidagen met dubbele reserveringen
        console.log("dubbeleDraaidagenIds:", dubbeleDraaidagenIds);
        //console.log("7812 ")
        setToonAlertDubbeleReserveringen(dubbeleDraaidagenIds.length > 0);
      };
    
      haalEnVerwerkReserveringen();
    }, []); // Deze useEffect wordt uitgevoerd bij component mount


    const calculateDaysDifference = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
  
      // Calculate difference in time
      const timeDifference = endDate.getTime() - startDate.getTime();
  
      // Calculate difference in days
      const daysDifference = timeDifference / (1000 * 3600 * 24) + 1; // Add 1 to include both start and end dates
  
      // Return appropriate string
      return daysDifference === 1 ? "1 dag" : `${daysDifference} dagen`;
    };

    // Convert draaidagen to events
    const events = draaidagen.map(record => {
        // Set a default style
        let style = {};

          // Check if logged-in user is in the EmailCameraman array
        const isUserCameramanForEvent = record.fields.EmailCameraman 
            ? record.fields.EmailCameraman.includes(email)
            : false;

        //console.log("email:", email)
        //console.log("record.fields.Draaidag.EmailCameraman:", record.fields.EmailCameraman)
        //console.log("isUserCameramanForEvent:", isUserCameramanForEvent)
        //console.log("record.fields:", record.fields	)
        //console.log("record.fields.AantalMateriaalGereserveerd:", record.fields.AantalMateriaalGereserveerd	)
        //console.log("draaidagenMetDubbeleReserveringen:", draaidagenMetDubbeleReserveringen)

        if (isUserCameramanForEvent) {
          if ((record.fields.AantalMateriaalGereserveerd>0) || record.fields['Geen Materiaal Nodig']) {
              style = {
                  backgroundColor: '#8cbd4c', // Green for cameraman events with Aantal > 0
              };
          } else {
              style = {
                  backgroundColor: '#de6a87', // Orange for other cameraman events
              };
          }
        } else if ((record.fields.AantalMateriaalGereserveerd>0)  || record.fields['Geen Materiaal Nodig']) {
            
            style = {
                backgroundColor: '#cbe7df', // Light cbe7df when Aantal > 0 or GeenMateriaalNodig is true for non-cameraman
                color: '#000000' // Black for text
            };
        } else { // Default style for other conditions
          //console.log("record.fields.Aantal>0:", record.fields.Aantal)  
          style = { 
                backgroundColor: '#ead1c0', // Light 
                color: '#000000' // Black for text
            };
        }
        
        if (draaidagenMetDubbeleReserveringen.includes(record.id)) {
            style = { 
                backgroundColor: '#FF0000', // Light 
                color: '#FFFFFF' // Black for text
            };
        }

        // Adjust title to include number of materials
        let title = record.fields.Draaidag;
        if (record.fields.ProjecttypeFromProject) {
          title += ` (Type: ${record.fields.ProjecttypeFromProject})`;
        }

        if (record.fields.Aantal && record.fields.Aantal > 0) {
          title = `${record.fields.Aantal} - ` + title;  
        }
  
        const startDate = new Date(record.fields.StartDatum);
        const endDate = new Date(record.fields.EindDatum);
    
        // If the event spans multiple days, set the end date to the start of the next day
        if (endDate.getTime() !== startDate.getTime()) {
            endDate.setDate(endDate.getDate() + 1); // Move to the start of the next day
        }

        // Set time to the start of the day for all-day events
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        //console.log("titel:", title)
        //console.log("endDate:", endDate)
        //console.log("startDate:", startDate)
        //console.log("record.fields:", record.fields)
        return {
          title: title,
          start: new Date(record.fields.StartDatum),
          end: new Date(record.fields.EindDatum),
          style: style,
          allDay: true,
          record: record 
        };
        // ... any other fields you want to include
    });

    // Fetch draaidagen from Airtable on component mount
    useEffect(() => {

      fetchDraaidagenFromBackend();
    }, []);
    
    // Handle drilldown on a date
    const handleDrillDown = (date) => {
      // Filter the events of the clicked date
      const eventsOnDate = events.filter(event => {
        const eventDate = new Date(event.start);
        return eventDate.toDateString() === date.toDateString();
      });
    
      setSelectedDateEvents(eventsOnDate);
      setDialogOpen(true);
    }


    // Handler to update the current view
    const handleViewChange = (view) => {
      setCurrentView(view);
      console.log("view:", view)
    };

    // Handle click on a mismatched reservation
    const handleMismatchedReservationClick = async (reservation) => {
      console.log("Mismatched reservation clicked:", reservation);

      // Set the current reservation
      //setCurrentReservation(reservation);
      if (!reservation.fields.DraaidagOutlook || reservation.fields.DraaidagOutlook.length === 0) {

        console.log("reservering zonder draaidag!");
        setGeselecteerdeDraaidag('EMPTY');
        setCurrentReservation(reservation);

      } else{
        const draaidag = draaidagen.find(draaidag => draaidag.id === reservation.fields.DraaidagOutlook[0]);
        console.log("draaidagclicked:", draaidag);
        setGeselecteerdeDraaidag(draaidag);
      

      }

    };

    const handleDraaidagClick = async(draaidag) => {
      console.log("Draaidag clicked:", draaidag);

      const fetchedReserveringen = await fetchReserveringen(draaidag.id);
      console.log(" ", fetchedReserveringen);
      if (Array.isArray(fetchedReserveringen.data.records)) {
          setDubbeleReserveringen(fetchedReserveringen.data.records);
        } else {
          console.error('Data is not an array:', fetchedReserveringen.data.records);
          setDubbeleReserveringen([]); // Zet een lege array als fallback
        }
      console.log("fetcheddubbeleReserveringen", fetchedReserveringen.data.records);
      if (fetchedReserveringen.data.records.length >= 2) {
            setDubbeleReserveringenDialogOpen(true);
          return;
      } 

      setGeselecteerdeDraaidag(draaidag);
    };
    
    if (loading) return <CircularProgress />;
  
    return (
    
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} md={10} lg={10}>
          {toonAlertDubbeleReserveringen && (
            <div style={{ backgroundColor: "#ffcccb", padding: "10px", marginTop: "20px", marginBottom: "20px", borderRadius: "5px", border: "1px solid red" }}>
              <strong>Let op:</strong> Er zijn draaidagen met dubbele reserveringen (rood gemarkeerd). Graag deze corrigeren door de draaidag te selecteren en de onjuiste reservering te verwijderen. Check ook <a href='https://airtable.com/apphMT5j6gtUnMY1p/pagWOK6C02DDm7YCt' target="_blank" rel="noopener noreferrer">Airtable Interface</a>
            </div>
          )}
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogContent>
              <List>
                {selectedDateEvents.map(event => (
                  <ListItem 
                    key={event.record.id} // Assuming each event record has a unique 'id' field
                    button 
                    onClick={() => setGeselecteerdeDraaidag(event.record)}
                    style={{ backgroundColor: event.style.backgroundColor }}
                  >
                    <ListItemText primary={event.title} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Legenda  with floating button*/}
          <Fab 
            color="primary" 
            aria-label="info" 
            style={{ position: 'absolute', bottom: 16, right: 16 }}
            onClick={() => setLegendaOpen(true)} // setLegendaOpen is een state setter die de legenda open/dicht doet
          >
            <Tooltip title="Legenda">
              <InfoIcon />
            </Tooltip>
          </Fab>

          {/* Legenda Dialog */}
          <Dialog open={legendaOpen} onClose={() => setLegendaOpen(false)}>
            <DialogTitle>Legenda</DialogTitle>
            <DialogContent>
              <List>
                {eventColorExplanation.map((item) => (
                  <ListItem key={item.label}>
                    <ListItemText primary={item.label} />
                    <div style={{ width: 24, height: 24, backgroundColor: item.color, borderRadius: '50%', marginLeft: 10 }} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </Dialog>
          <DubbeleReserveringenDialog
            key={dubbeleReserveringenDialogOpen}
            open={dubbeleReserveringenDialogOpen}
            onClose={() => setDubbeleReserveringenDialogOpen(false)}
            reserveringen={dubbeleReserveringen}
            handleDeleteReservation={handleDeleteReservation}
          />
          <MismatchedReservations 
            draaidag={draaidagen} 
            handleDeleteReservation={handleDeleteReservation}
            reservationsData={reservationsData}
            onReservationClick={handleMismatchedReservationClick}
            setGeselecteerdeDraaidag={setGeselecteerdeDraaidag}
          />
          <div style={{ marginTop: '5px' }}>
            <Calendar
              showMultiDayTimes 
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 800 }}
              views={[ 'month', 'work_week', 'agenda']}            
              view={currentView} // Use the state variable here
              onView={handleViewChange} // Update the state variable when the view changes 
              onSelectEvent={event => handleDraaidagClick(event.record)}
              onDrillDown={handleDrillDown}

              eventPropGetter={(event, start, end, isSelected) => {
                return {
                  style: event.style,
                };
              }}           

            />
          </div>
        </Grid>
      </Grid>
    );
    
  }
  