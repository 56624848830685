// Import the function to add a new structure from another module
import returnNewStructure from "./AddPartToStructure"

// Asynchronous function to attach a 'draaidag' (shooting day) task to the parts of a project
// This function is meant to orchestrate the attachment of tasks to specific roles within a project
export default async function AttachDraaidag(_Air, Structure, Functions, tasks, week, userID, email) {
    let newStructure = Structure; // Start with the existing structure

    // Loop through each task provided
    for (const task of tasks) {
        // Fetch all parts related to the task's project
        const projectParts = await _Air.GetPartsFromProject(task.project);

        // If no parts are found, skip to the next task
        if (projectParts.length === 0) {
            return newStructure;
        }

        // Loop through each function role needed for the task
        for (const Function of Functions) {
            // Handle different function roles with a switch statement
            switch (Function) {
                case "Regisseur": // Director
                    newStructure = await attachPartByRole(newStructure, task, week, projectParts, userID, "Regisseur", _Air, email);
                    break;
                case "Cameraman": // Camera operator
                    newStructure = await attachPartByRole(newStructure, task, week, projectParts, userID, "Cameraman", _Air, email);
                    break;
                // Additional cases for other roles can be added here
                default:
                    break;
            }
        }
    }

    // Return the updated structure after processing all tasks and roles
    return newStructure;
}

// Helper function to attach parts by their specific role (function) within a project
async function attachPartByRole(Structure, task, week, projectParts, userID, functie, _Air, email) {
    // Filter parts by their type (role)
    const parts = getParts(projectParts, functie);
    // Extract the project name from the first part
    const projectName = projectParts[0].fields["Projectnr_en_naam (from Projecten)"][0];

    // Loop through each part matching the role
    for (const part of parts) {
        // Check if the project name is already in the structure
        if (Structure.hasOwnProperty(projectName)) {
            // Find the matching element in the structure by part ID
            const matchingElement = Structure[projectName].find(x => x.ID === part.fields.autonumber);
            if (matchingElement) {
                // If a matching element is found, assign the task to it
                matchingElement['task'] = task;
                return Structure;
            }
        }
        // If no matching element is found, add the new structure for the part
        return returnNewStructure([part], Structure, userID, _Air, week, email, task);
    }

    // If no matching parts are found, return the unchanged structure
    return Structure;
}

// Function to get parts from project parts based on the specified type (role)
function getParts(projectParts, type) {
    return projectParts.filter(x => x.fields["Taaktype (from StandardOnderdeel)"]?.[0] === type);
}

