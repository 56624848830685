import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import axios from 'axios';



import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  TextField,
  Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';

const MismatchedReservations = ({reservationsData,handleDeleteReservation, onReservationClick,setCurrentReservation, setGeselecteerdeDraaidag }) => {

    const [mismatchedReservations, setMismatchedReservations] = useState([]);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [reservationToDelete, setReservationToDelete] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [draaidagSelectDialogOpen, setDraaidagSelectDialogOpen] = useState(false);
    const [availableDraaidagen, setAvailableDraaidagen] = useState([]);
    const [reservationToLink, setReservationToLink] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');



    useEffect(() => {
        console.log('reservations changed');
        console.log('reservations mismatch', reservationsData);
    
        if (Array.isArray(reservationsData)) { // Voeg deze controle toe
            const getMismatchedReservations = (reservationsData) => {
                return reservationsData.filter(reservation => reservation.fields.misMatchDataOutlook === 'True');
            };
    
            const mismatched = getMismatchedReservations(reservationsData);
            setMismatchedReservations(mismatched);
        }
    }, [reservationsData]); // Run when reservationsData changes
  
    const handleChange = () => {
        setExpanded(!expanded);
    };

    if (mismatchedReservations.length === 0) {
        return null; // Return null or some other placeholder if there are no mismatched reservations
    }    

    const initiateDeleteReservation = (reservationId) => {
        setReservationToDelete(reservationId);
        setConfirmDeleteDialogOpen(true);
    };

    const fetchAvailableDraaidagen = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CURRENT_URL}/Api/availableDraaidagen`); 
            setAvailableDraaidagen(response.data);
            console.log("Available draaidagen fetched:", response.data);
        } catch (error) {
            console.error("Error fetching available draaidagen:", error);
        }
    };
    
    const openReservation = (reservationId) => {
        setCurrentReservation(reservationId);
        setGeselecteerdeDraaidag('EMPTY');
    };

    const handleReservationClick = (reservation) => {
        console.log('clicked reservation', reservation);
    
        if (!reservation.fields.DraaidagOutlook || reservation.fields.DraaidagOutlook.length === 0) {
            alert("Let op! Deze reservering is niet gekoppeld aan een draaidag. Selecteer een draaidag om te koppelen.");
            setReservationToLink(reservation);
            fetchAvailableDraaidagen();
            setDraaidagSelectDialogOpen(true);
        } else {
            onReservationClick(reservation);
        }
    };

    const handleDraaidagSelection = async (selectedDraaidag) => {
        try {
            const updatedReservation = { 
                ...reservationToLink,
                fields: {
                    ...reservationToLink.fields,
                    DraaidagOutlook: [selectedDraaidag.id],
                }
            };
            
            await axios.patch(`${process.env.REACT_APP_CURRENT_URL}/Api/reservations/${reservationToLink.id}`, updatedReservation);
    
            setDraaidagSelectDialogOpen(false);
            setReservationToLink(null);
            alert("Reservering succesvol gekoppeld aan de draaidag.");
            // Herlaad de mismatched reservations of voer een andere update uit indien nodig
        } catch (error) {
            console.error("Error updating reservation with draaidag:", error);
            alert("Er is een fout opgetreden bij het koppelen van de draaidag.");
        }
    };
    

    return (
        <>
        <Paper style={{ padding: '20px', marginTop: '20px', marginBottom: '20px' }}>
            <Alert severity="warning" onClick={handleChange} style={{ cursor: 'pointer', marginBottom: '20px' }}>
                <AlertTitle>Waarschuwing</AlertTitle>
                Er zijn reserveringen met mismatched data. — <strong>Klik om te bekijken!</strong>
            </Alert>
            <Accordion expanded={expanded} onChange={handleChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Controleer onderstaande reserveringen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        Bij onderstaande reserveringen kloppen de data van de draaidag niet meer met de data van de originele reservering. Door op de reservering te klikken kan je de data van de reservering wijzigen zodat deze weer overeenkomt met data van draaidag.
                    </Typography>
                    <List>
                        {mismatchedReservations.map(mismatchedReservation => (
                            <ListItem key={mismatchedReservation.id} button onClick={() => handleReservationClick(mismatchedReservation)}>
                                <ListItemText 
                                    primary={`Reservering: ${mismatchedReservation.fields.reserveringNummer} - ${mismatchedReservation.fields.Draaidag}`} 
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2" color="textPrimary" display="block">
                                                Draaidagagenda: {`${mismatchedReservation.fields.OrigineleDraaidagNaam}`}
                                            </Typography>
                                            <Typography component="span" variant="body2" color="textPrimary" display="block">
                                                {mismatchedReservation.fields.startDatumDraaidag} - {mismatchedReservation.fields.eindDatumDraaidag} (draaidagdata) 
                                            </Typography>
                                            <Typography component="span" variant="body2" color="textPrimary" display="block">
                                                {mismatchedReservation.fields.Start} - {mismatchedReservation.fields.Eind} (reserveringsdata)
                                            </Typography>
                                            <Typography color="error">Mismatch!</Typography>
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Button edge="end" aria-label="delete" onClick={() => initiateDeleteReservation(mismatchedReservation.id)}>
                                        Verwijder reservering
                                    </Button>

                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Paper>
        <Dialog open={confirmDeleteDialogOpen} onClose={() => setConfirmDeleteDialogOpen(false)}>
            <DialogTitle>Bevestig Verwijdering</DialogTitle>
            <DialogContent>
                <Typography>
                    Weet u zeker dat u deze reservering wilt verwijderen?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDeleteDialogOpen(false)}>Annuleren</Button>
                <Button color="primary" onClick={() => {
                    handleDeleteReservation(reservationToDelete);
                    setConfirmDeleteDialogOpen(false);
                }}>
                    Verwijderen
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={draaidagSelectDialogOpen} onClose={() => setDraaidagSelectDialogOpen(false)}>
            <DialogTitle>Selecteer Draaidag</DialogTitle>
            <DialogContent>
                <Typography>
                    Selecteer een draaidag om te koppelen aan deze reservering:
                </Typography>

                {/* Zoekbalk */}
                <TextField 
                    label="Zoek draaidag" 
                    variant="outlined" 
                    fullWidth 
                    margin="normal" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                {/* Gefilterde draaidagenlijst */}
                <List>
                    {availableDraaidagen
                        .filter(draaidag => draaidag.fields.Draaidag && draaidag.fields.Draaidag.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map(draaidag => (
                            <ListItem key={draaidag.id} button onClick={() => handleDraaidagSelection(draaidag)}>
                                <ListItemText 
                                    primary={draaidag.fields.Draaidag} 
                                    secondary={`${draaidag.fields.StartDatum} - ${draaidag.fields.EindDatum}`}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDraaidagSelectDialogOpen(false)}>Annuleren</Button>
            </DialogActions>
        </Dialog>



        </>
        
    
    );
};

export default MismatchedReservations;
