import AirtableData from "./API/AirtableData.json"

/**
 * Creates new structure object
 * @param {*} parts all parts with the new part added
 * @param {*} structure old structure object
 * @param {*} userID 
 * @param {*} _Air AirHour class needed to get hours
 * @param {*} week 
 * @param {*} email 
 * @param {*} task 
 * @returns new Structure with new part added
 */
// Export default function to asynchronously create or update a structure based on parts, including tasks
export default async function returnNewStructure(parts, structure, userID, _Air, week, email, task = undefined) {
    // Iterate over each part passed to the function
    for (const part of parts) {
        // Extract the project name and number from the part's related project
        const partProjectName = part.fields[AirtableData.Operational.children.Onderdeel.children["Projectnr_en_naam (from Projecten)"]];
        
        // Create a new structure for the current part with details specific to the user
        let partStuc = await CreatePartStructure(part, userID);
        
        // If a task is provided, add it to the part structure and mark it as pinned
        if (task !== undefined) {
            partStuc['task'] = task; // Assign the task to the part structure
            partStuc.Pinned = true;  // Pin this part to indicate it has a specific task associated
        }

        // Fetch all hours logged for this part for the specified week and email, updating partStuc with the data
        partStuc = await getAllHours(_Air, partStuc, week, email);

        // If the structure is initially empty, start it by adding the current part
        if (structure.length === 0) {
            structure['partname'] = partStuc;
        } else {
            // If the structure is not empty, check if it already contains an entry for the project
            if (structure.hasOwnProperty(partProjectName)) {
                // If the project is already in the structure, push the current part structure to it
                structure[partProjectName].push(partStuc);
            } else {
                // If the project is not in the structure, add it with the current part structure as the first element
                structure[partProjectName] = [partStuc];
            }
        }
    }

    // Return the modified or newly created structure
    return structure;
}


/**
 * Formats data in new object
 * @param {*} Part 
 * @param {*} userID 
 * @returns array of part objects
 */
async function CreatePartStructure(Part, userID){
    const partNames ={
        ID : Part.fields[AirtableData.Operational.children.Onderdeel.children.autonumber],
        UserID: userID,
        Name: Part.fields[AirtableData.Operational.children.Onderdeel.children["offerteregelOmschrijving"]].split('(')[0],
        projectName : Part.fields[AirtableData.Operational.children.Onderdeel.children["Projectnr_en_naam (from Projecten)"]][0],
        onderdeelRecID: Part.id,
        Aantal: Part.fields[AirtableData.Operational.children.Onderdeel.children.Aantal],
        KorteOmschrijving : Part.fields[AirtableData.Operational.children.Onderdeel.children["OmschrijvingCategorie not empty"]],
        LangeOmschrijving : Part.fields[AirtableData.Operational.children.Onderdeel.children["Categorie not empty"]],
        Unit: Part.fields[AirtableData.Operational.children.Onderdeel.children.Eenheid.name],
        Group : Part.fields[AirtableData.Operational.children.Onderdeel.children["Group not empty"]],
        hideForTimeWriting : Part.fields[AirtableData.Operational.children.Onderdeel.children.hideForTimeWriting.name],
        Pinned : false
    }

    return partNames
}
/**
 * Gets hour of the week of a part
 * @param {*} _Air 
 * @param {*} part 
 * @param {*} week 
 * @param {*} email 
 * @returns 
 */
async function getAllHours(_Air,part,week,email){
    await _Air.StartRequest();
    try {
        const GetHours = await _Air._Uren.GetHours(email, part.ID, week);
        if (GetHours !== undefined) {
            part["hours"] = GetHours[0];
        }
    } finally {
        _Air.CompleteRequest();
    }
    await _Air.StartRequest()
    try{
        const sumHours = await _Air._Uren.GetSumHours(part.ID)
        if(sumHours !== undefined){
            part['sumHours'] = sumHours[0].fields.SumHours
        }
    }finally{
        _Air.CompleteRequest()
    }

    return part
}


